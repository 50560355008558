import React from 'react'
import img from '../img/icon/icon1.svg'
import img1 from '../img/icon/icon2.svg'
import img2 from '../img/icon/icon3.svg'
const Servicearea = () => {
  return (
   <>
  <div class="services-area">
    <div class="container">
        <div class="row justify-content-sm-center">
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services mb-30">
                    <div class="features-icon">
                        <img src={img} alt="3D Courses"/>
                    </div>
                    <div class="features-caption">
                        <h3>Comprehensive 3D Courses</h3>
                        <p>Access a wide range of courses designed to master the art and science of 3D modeling, animation, and visualization.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services mb-30">
                    <div class="features-icon">
                        <img src={img2} alt="Expert Instructors"/>
                    </div>
                    <div class="features-caption">
                        <h3>Industry Expert Instructors</h3>
                        <p>Learn from top professionals with years of experience in the 3D industry, providing you with valuable insights and practical knowledge.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services mb-30">
                    <div class="features-icon">
                        <img src={img2} alt="Lifetime Access"/>
                    </div>
                    <div class="features-caption">
                        <h3>Lifetime Access</h3>
                        <p>Enjoy lifetime access to course materials and updates, ensuring you stay up-to-date with the latest advancements in 3D technology.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

   </>
  )
}

export default Servicearea