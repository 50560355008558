import React, { useState } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import Topbar from '../../scenes/global/Topbar';
import Sidebar from '../../scenes/global/Sidebar';
import { useNavigate } from 'react-router-dom';
import { BASE_SERVER_URL } from '../../utils/constant/Api';

const AddProduct = () => {
  const [togglebar ,setTogglebar] = useState()
  const [product, setProduct] = useState({
    name: '',
    originalPrice: '',
    course: '',
    year: '',
    Price: '',
    category: '',
  });
  const navigate = useNavigate()
  const [media, setmedia] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handlemediaChange = (e) => {
    setmedia(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('originalPrice', product.originalPrice);
    formData.append('course', product.course);
    formData.append('year', product.year);
    formData.append('Price', product.Price);
    formData.append('category', product.category);
    if (media) {
      formData.append('media', media);
    }
    const token = localStorage.getItem("token");
    Axios.post(`${BASE_SERVER_URL}/product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        token: `${token}`
      },
    })
      .then(response => {
        Swal.fire({
          title: 'Success!',
          text: 'Product added successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        },navigate("/Productlist"));
      })
      .catch(error => {
        Swal.fire({
          title: 'Error!',
          text: 'There was an error adding the product.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
  };
  const handleSidebarToggle = (isOpen) => {
    setTogglebar(isOpen);
  };
  const handleProductlist = () =>{
    navigate("/Productlist")
  }
  return (
    <>
      <Topbar />
      <Sidebar setonTogglebar={handleSidebarToggle} />
      <div className={`container mt-4  ${togglebar ? 'sidebar-open' : ''} `}>
        <h2 className="mb-4 table-list">Add Product</h2>
        <form onSubmit={handleSubmit} className="needs-validation table-list" noValidate>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="name" className="form-label">Product Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={product.name}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">
                Please provide a product name.
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="originalPrice" className="form-label">Original Price</label>
              <input
                type="number"
                className="form-control"
                id="originalPrice"
                name="originalPrice"
                value={product.originalPrice}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">
                Please provide the original price.
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="course" className="form-label">Course</label>
              <input
                type="text"
                className="form-control"
                id="course"
                name="course"
                value={product.course}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">
                Please provide the course.
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="year" className="form-label">Year</label>
              <input
                type="number"
                className="form-control"
                id="year"
                name="year"
                value={product.year}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">
                Please provide the year.
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="Price" className="form-label">Price</label>
              <input
                type="number"
                className="form-control"
                id="Price"
                name="Price"
                value={product.Price}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="category" className="form-label">Category</label>
              <input
                type="text"
                className="form-control"
                id="category"
                name="category"
                value={product.category}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">
                Please provide the category.
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="media" className="form-label">Product media</label>
              <input
                type="file"
                className="form-control"
                id="media"
                name="media"
                accept="media/*"
                onChange={handlemediaChange}
              />
            </div>


          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary">Add Product</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddProduct;
