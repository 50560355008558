import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Topbar from '../global/Topbar';
import Sidebar from '../global/Sidebar';
import './index.css'
import { BASE_SERVER_URL } from '../../utils/constant/Api';
const Index = () => {
  const [togglebar, setTogglebar] = useState();
  const [totals, setTotals] = useState({
    products: 0,
    gallery: 0,
    contact: 0,
  });

  const handleSidebarToggle = (isOpen) => {
    setTogglebar(isOpen);
  };

  useEffect(() => {
    // Fetch data from APIs
    const fetchTotals = async () => {
      try {
        const productResponse = await axios.get(`${BASE_SERVER_URL}/productget`);
        const galleryResponse = await axios.get(`${BASE_SERVER_URL}/gallery`);
        const contactResponse = await axios.get(`${BASE_SERVER_URL}/contact`);
        console.log(contactResponse.data);
        setTotals({
          products: productResponse.data.total || 0,
          gallery: galleryResponse.data.totalProducts || 0,
          contact: contactResponse.data.totalContacts || 0,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTotals();
  }, []);

  return (
    <>
      <Topbar />
      <Sidebar setonTogglebar={handleSidebarToggle} />
      <div className={`container mt-5  ${togglebar ? 'open' : ''} `}>
        <div className="row justify-content-center" style={{marginLeft:"83px"}} >
          <div className="col-md-6 col-lg-5 mb-4">
            <div className="card text-center shadow-lg p-5" style={{ width: '100%',height:"200px" }}>
              <div className="card-body">
                <h5 className="card-title mt-5">Total Products</h5>
                <p className="card-text">{totals.products}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-5 mb-4">
            <div className="card text-center shadow-lg p-5" style={{ width: '100%',height:"200px" }}>
              <div className="card-body">
                <h5 className="card-title mt-5">Total Gallery</h5>
                <p className="card-text">{totals.gallery}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-5 mb-4">
            <div className="card text-center shadow-lg p-5" style={{ width: '100%',height:"200px" }}>
              <div className="card-body">
                <h5 className="card-title mt-5">Total Contacts</h5>
                <p className="card-text">{totals.contact}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
