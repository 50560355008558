import React, { useState } from 'react';
import { FaHome, FaUser, FaBook, FaCog, FaBars, FaTimes } from 'react-icons/fa';
import './Sidebar.css'; // Make sure to update this CSS file
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import { IoMdContacts } from "react-icons/io";
import { TfiGallery } from "react-icons/tfi";
const Sidebar = ({ setonTogglebar }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setonTogglebar(!isOpen);
  };
  const navigate = useNavigate()
const handleAddproduct = () =>{
  navigate("/AddProduct")
}
const handleHome = () =>{
  navigate("/Dashboard")
}
const handleProductlist = () =>{
  navigate("/Productlist")
}
const handleContact = () =>{
  navigate("/Contactget")
}

const handleGalleryComponent = () =>{
  navigate("/GalleryComponent")
}
  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <button className="toggle-btn" onClick={handleToggle}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div className="sidebar-content">
        <div className="sidebar-item" onClick={handleHome}>
          <FaHome className="sidebar-icon" />
          {isOpen && <span className="sidebar-text">Home</span>}
        </div>
        <div className="sidebar-item" onClick={handleAddproduct}>
          <IoMdAdd className="sidebar-icon" />
          {isOpen && <span className="sidebar-text">Add Product</span>}
        </div>
        <div className="sidebar-item" onClick={handleProductlist}>
          <FaBook className="sidebar-icon"  />
          {isOpen && <span className="sidebar-text">Product list</span>}
        </div>
        <div className="sidebar-item" onClick={handleContact}>
          <IoMdContacts className="sidebar-icon" />
          {isOpen && <span className="sidebar-text">Contact</span>}
        </div>
   
        <div className="sidebar-item" onClick={handleGalleryComponent}>
          <TfiGallery className="sidebar-icon" />
          {isOpen && <span className="sidebar-text">Gallery list</span>}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
