import React from "react";
import Preloader from "../Component/Preloader";
import Header from "../Component/Header";
import Banner from "../Component/Banner";
import '../App.css'
import Servicearea from "../Component/Servicearea";
import Coursesarea from "../Component/Coursesarea";
import Aboutarea1 from "../Component/Aboutarea1";
import Subjectarea from "../Component/Subjectarea";
import Aboutarea2 from "../Component/Aboutarea2";
import Team from "../Component/Team";
import Aboutarea3 from "../Component/Aboutarea3";
import Footer from "../Component/Footer";
const Home = () => {
  return (
    <>
      <Header />
      <Banner/>
      <Servicearea/>
      <Coursesarea/>
      <Aboutarea1/>
      <Subjectarea/>
      <Aboutarea2/>
      <Team/>
      <Aboutarea3/>
      <Footer/>
    </>
  );
};

export default Home;
