import React from 'react'
import img from '../img/gallery/topic1.png'
import img2 from '../img/gallery/topic2.png'
import img3 from '../img/gallery/topic3.png'
import img4 from '../img/gallery/topic4.png'
import img5 from '../img/gallery/topic5.png'
import img6 from '../img/gallery/topic6.png'
import img7 from '../img/gallery/topic7.png'
import img8 from '../img/gallery/topic8.png'
import { Link } from 'react-router-dom'
const Subjectarea = () => {
  return (
        <>
         <div class="topic-area section-padding40">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-7 col-lg-8">
                        <div class="section-tittle text-center mb-55">
                            <h2>Explore top Courses</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img2} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img3} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img4} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img5} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img6} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img7} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="single-topic text-center mb-30">
                            <div class="topic-img">
                                <img src={img8} alt=""/>
                                <div class="topic-content-box">
                                    <div class="topic-content">
                                        <h3><Link to="#">Programing</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="section-tittle text-center mt-20">
                            <Link to="/" class="border-btn">View Top Courses</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
  )
}

export default Subjectarea