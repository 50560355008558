import React from 'react'
import img from '../img/icon/about.svg'
import img1 from '../img/icon/right-icon.svg'
import img2 from '../img/grow.avif'
const Aboutarea1 = () => {
  return (
    <>
         <section class="about-area1 fix pt-10">
    <div class="support-wrapper align-items-center">
        <div class="left-content1">
            <div class="about-icon">
                <img src={img} alt="3D Skills"/>
            </div>
            <div class="section-tittle section-tittle2 mb-55">
                <div class="front-text">
                    <h2 class="">Why Choose Our Course?</h2>
                    <p>Get special knowledge from the successful practitioners in the sphere of 3D printing and design.</p>
                </div>
            </div>
            <div class="single-features">
                <div class="features-icon">
                    <img src={img1} alt=""/>
                </div>
                <div class="features-caption">
                    <p>Fear with citing it extensively is therefore emphasizing on the practical projects where the knowledge can be put into practice.
                    </p>
                </div>
            </div>
            <div class="single-features">
                <div class="features-icon">
                    <img src={img1} alt=""/>
                </div>
                <div class="features-caption">
                    <p>Easy to organize to work into anyone and anywhere as it contains modular approach to suits any time table.
                    </p>
                </div>
            </div>
            <div class="single-features">
                <div class="features-icon">
                    <img src={img1} alt=""/>
                </div>
                <div class="features-caption">
                    <p>A community to share the ideas that has been developed and where people can offer their opinions about it.
                    </p>
                </div>
            </div>
        </div>
        <div class="right-content1" >
            <div class="right-img" >
                <img src={img2} alt="3D Learning" />
               
            </div>
        </div>
    </div>
</section>

    </>
  )
}

export default Aboutarea1