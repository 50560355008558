import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Topbar from '../../scenes/global/Topbar';
import Sidebar from '../../scenes/global/Sidebar';
import Swal from 'sweetalert2';
import { BASE_SERVER_URL } from '../../utils/constant/Api';

const Contactget = () => {
  const [contacts, setContacts] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [togglebar, setTogglebar] = useState(false);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await Axios.get(`${BASE_SERVER_URL}/contact`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setContacts(response.data.contacts);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleDelete = async (contactId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This contact will be deleted permanently.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      try {
        await Axios.delete(`${BASE_SERVER_URL}/contact/${contactId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        fetchContacts();
        Swal.fire('Deleted!', 'The contact has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting contact:', error);
        Swal.fire('Error!', 'There was an error deleting the contact.', 'error');
      }
    }
  };

  const handleSidebarToggle = (isOpen) => {
    setTogglebar(isOpen);
  };

  return (
    <>
      <Topbar />
      <Sidebar setonTogglebar={handleSidebarToggle} />
      <div className={`container mt-4 ${togglebar ? 'sidebar-open' : ''}`}>
        <div className="row">
          <div className="col-12">
            <h1 className="mb-4 table-list">Contact List</h1>
            <div className="table-responsive table-list">
              <table className="table table-striped table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Number</th>
                    <th>Message</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((contact, index) => (
                    <tr key={contact._id}>
                      <td>{index + 1}</td>
                      <td>{contact.name}</td>
                      <td>{contact.email}</td>
                      <td>{contact.number}</td>
                      <td>{contact.message}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDelete(contact._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactget;
