import React from 'react'
import img from '../img/gallery/about3.png'
import img1 from '../img/icon/right-icon.svg'

const Aboutarea2 = () => {
  return (
   <>
  <section class="about-area3 fix">
    <div class="support-wrapper align-items-center">
        <div class="right-content3">
            <div class="right-img">
                <img src={img} alt="3D Skills"/>
            </div>
        </div>
        <div class="left-content3">
            <div class="section-tittle section-tittle2 mb-20">
                <div class="front-text">
                    <h2 class="">What You Will Achieve with Our 3D Courses</h2>
                </div>
            </div>
            <div class="single-features">
                <div class="features-icon">
                    <img src={img1} alt=""/>
                </div>
                <div class="features-caption">
                    <p>Master essential 3D modeling and animation techniques to create stunning visual content.</p>
                </div>
            </div>
            <div class="single-features">
                <div class="features-icon">
                    <img src={img1} alt=""/>
                </div>
                <div class="features-caption">
                    <p>Gain hands-on experience with real-world projects and case studies guided by industry professionals.</p>
                </div>
            </div>
            <div class="single-features">
                <div class="features-icon">
                    <img src={img1} alt=""/>
                </div>
                <div class="features-caption">
                    <p>Become part of a global network of learners and professionals, enhancing your career opportunities in the 3D field.</p>
                </div>
            </div>
        </div>
    </div>
</section>

   </>
  )
}

export default Aboutarea2