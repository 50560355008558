import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Component/Header';
import { BASE_SERVER_URL } from '../utils/constant/Api';

const Userorder = () => {
  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const Id = localStorage.getItem("id");
        const response = await axios.get(`${BASE_SERVER_URL}/user/${Id}`);
        setOrderData(response.data); // Store fetched data in state
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    fetchOrderData();
  }, []);

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "10pc" }}>
        {orderData.length > 0 ? (
          orderData.map((order) => (
            <div key={order._id} className="card mb-4 shadow-sm">
              <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                <h2 className="mb-0">Order Details</h2>
                <span className="badge bg-light text-dark">Order ID: {order._id}</span>
              </div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <p><strong>Name:</strong> {order.user}</p>
                    <p><strong>Phone Number:</strong> {order.phoneNo}</p>
                  </div>
                  <div className="col-md-6">
                    <p><strong>Total Amount:</strong> ₹{order.totalAmount}</p>
                    <p><strong>Order Status:</strong> <span className={`badge ${order.orderStatus === 'Delivered' ? 'bg-success' : 'bg-warning'}`}>{order.orderStatus}</span></p>
                    <p><strong>Payment Status:</strong> <span className={`badge ${order.paymentStatus === 'Paid' ? 'bg-success' : 'bg-danger'}`}>{order.paymentStatus}</span></p>
                  </div>
                </div>

                <h3 className="mt-4 mb-3">Shipping Address</h3>
                <div className="alert alert-info">
                  <p>
                    {order.shippingAddress.addressLine1}, {order.shippingAddress.localArea}, {order.shippingAddress.district}, {order.shippingAddress.state}, {order.shippingAddress.country} - {order.shippingAddress.pincode}
                  </p>
                </div>

                <h3 className="mt-4 mb-3">Products</h3>
                {order.products.map((product) => (
                  <div key={product._id} className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-4 p-2">
                        <img
                          src={product.product.media ? `${BASE_SERVER_URL}/${product.product.media}` : ""}
                          alt="Product"
                          className="img-fluid rounded-start"
                          style={{ height: "200px", objectFit: "cover" }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <p className="card-title"><strong>Product Name:</strong> {product.product.name}</p>
                          <p className="card-text"><strong>Quantity:</strong> {product.quantity}</p>
                          <p className="card-text"><strong>Price:</strong> ₹{product.price}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <p className="mt-3"><strong>Order Date:</strong> <span className="text-muted">{new Date(order.createdAt).toLocaleString()}</span></p>
              </div>
            </div>
          ))
        ) : (
          <p>No order available</p>
        )}
      </div>
    </>
  );
}

export default Userorder;
