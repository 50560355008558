import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom'; // If you're using react-router for navigation
import video from "../img/login-bg.mp4";
import img from '../img/logo/loder.png';
import { BASE_SERVER_URL } from "../utils/constant/Api";

const Signup = () => {
  const navigate = useNavigate();
  
  // State to handle input values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    confirmPassword: ""
  });

  // State to handle error messages or success
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Basic validation
    if (!formData.number || formData.number.length !== 10) {
      setError("Please enter a valid 10-digit phone number");
      return;
    }
    
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (formData.password.length < 8) {
      setError("Password should be at least 8 characters long");
      return;
    }

    try {
      const response = await axios.post(`${BASE_SERVER_URL}/signup`, { 
        name: formData.name,
        email: formData.email,
        number: formData.number,
        password: formData.password,
        confPassword: formData.confirmPassword
      });

      if (response.status === 200) {
        setSuccess("Registration successful!");
        navigate('/login'); // redirect to login page after successful signup
      } else {
        setError(response.data.message || "Something went wrong");
      }
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setError("Email is already in use, please enter a unique email");
      } else {
        setError("Network error, please try again");
      }
    }
  };

  return (
    <main className="login-body">
      <video autoPlay muted loop className="background-video">
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <form className="form-default" onSubmit={handleSubmit}>
        <div className="login-form">
          <div className="logo-login">
            
              <img src={img} alt="Logo" />
      
          </div>
          <h2>Registration Here</h2>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}

          <div className="form-input">
            {/* <label htmlFor="name">Full name</label> */}
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Full name"
              required
            />
          </div>
          <div className="form-input">
            {/* <label htmlFor="email">Email Address</label> */}
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              required
            />
          </div>
          <div className="form-input">
            {/* <label htmlFor="number">Phone Number</label> */}
            <input
              type="text"
              name="number"
              value={formData.number}
              onChange={handleChange}
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="form-input">
            {/* <label htmlFor="password">Password</label> */}
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
          </div>
          <div className="form-input">
            {/* <label htmlFor="confirmPassword">Confirm Password</label> */}
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              required
            />
          </div>
          <div className="form-input pt-30">
            <input type="submit" name="submit" value="Register" />
            <Link to="/" className="forget text-center">
              <h5>Go to home</h5>
            </Link>
            <Link to="/login" className="registration text-center">
              <h5>Already have an account? Login</h5>
            </Link>
          </div>
        </div>
      </form>
    </main>
  );
};

export default Signup;
