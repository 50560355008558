import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Component/Header';
import Topbar from '../scenes/global/Topbar';
import Sidebar from '../scenes/global/Sidebar';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Footer from '../Component/Footer';
import'../style/gallery.css'
import { BASE_SERVER_URL } from '../utils/constant/Api';

const Gallery = () => {
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const role = localStorage.getItem("role");
  const [togglebar, setTogglebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(`${BASE_SERVER_URL}/gallery`);
        setMediaData(response.data.posts);
        setLoading(false);
      } catch (err) {
        setError('Error fetching media');
        setLoading(false);
      }
    };

    fetchMedia();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_SERVER_URL}/gallery/${id}`);
          setMediaData(mediaData.filter(item => item._id !== id));
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        } catch (error) {
          Swal.fire('Error!', 'Failed to delete the file.', 'error');
        }
      }
    });
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setNewDescription(item.description);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      const updatedItem = { ...editItem, description: newDescription };
      await axios.put(`${BASE_SERVER_URL}/gallery/${editItem._id}`, updatedItem);
      setMediaData(
        mediaData.map((item) => (item._id === editItem._id ? updatedItem : item))
      );
      setShowModal(false);
    } catch (err) {
      console.error('Error updating media:', err);
      alert('Failed to update media item.');
    }
  };

  // if (loading) {
  //   return <div className="text-center my-5">Loading media...</div>;
  // }

  // if (error) {
  //   return <div className="text-center text-danger my-5">{error}</div>;
  // }

  const handleSidebarToggle = (isOpen) => {
    setTogglebar(isOpen);
  };

  const handleAddgallery = () => {
    navigate("/Addgallery");
  };

  return (
    <>
      {role === 'user' && (
        <>
          <Header />
          <div className="container gallery-container" style={{ marginTop: "6pc" }}>
            <h1 className='text-center p-5'>Gallery</h1>
            {mediaData.length > 0 ? (
            <div className="row">
              {mediaData.map((item) => (
                <div key={item._id} className="col-md-4 mb-4">
                  <div className="card user-card">
                    <div className="media-container user-media-container">
                      {item.media && item.media.endsWith('.mp4') ? (
                        <video controls className="card-img-top">
                          <source src={`${BASE_SERVER_URL}/${item.media}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={item.media ? `${BASE_SERVER_URL}/${item.media}` : ""}
                          onError={(e) => (e.target.src = 'https://via.placeholder.com/150')}
                          alt={item.description}
                          className="card-img"
                        />
                      )}
                    </div>
                    <div className="card-body">
                      <p className="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
                 ) : (
                  <div className="text-center my-5">No data available</div>
                )}
          </div>
          <Footer />
        </>
      )}
      {role === null && (
        <>
          <Header />
          <div className="container gallery-container" style={{ marginTop: "6pc" }}>
            <h1 className='text-center p-5'>Gallery</h1>
            {mediaData.length > 0 ? (
            <div className="row">
              {mediaData.map((item) => (
                <div key={item._id} className="col-md-4 mb-4">
                  <div className="card public-card">
                    <div className="media-container public-media-container">
                      {item.media && item.media.endsWith('.mp4') ? (
                        <video controls className="card-img-top">
                          <source src={`${BASE_SERVER_URL}/${item.media}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={item.media ? `${BASE_SERVER_URL}/${item.media}` : ""}
                          onError={(e) => (e.target.src = 'https://via.placeholder.com/150')}
                          alt={item.description}
                          className="card-img"
                        />
                      )}
                    </div>
                    <div className="card-body">
                      <p className="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
                 ) : (
                  <div className="text-center my-5">No data available</div>
                )}
          </div>
          <Footer />
        </>
      )}
      {role === 'admin' && (
        <>
          <Topbar />
          <Sidebar setonTogglebar={handleSidebarToggle} />
          <div className={`container gallery-container ${togglebar ? 'sidebar-open' : ''}`}>
            <h1 className="text-center p-5">Gallery</h1>
            <button onClick={handleAddgallery} className="btn btn-primary ms-auto d-block mb-5">Add Gallery image</button>
            {mediaData.length > 0 ? (
            <div className="row" style={{marginLeft:"78px"}}>
              {mediaData.map((item) => (
                <div key={item._id} className="col-md-4 mb-4">
                  <div className="card admin-card">
                    <div className="media-container admin-media-container">
                      {item.media && item.media.endsWith('.mp4') ? (
                        <video controls className="card-img-top">
                          <source src={`${BASE_SERVER_URL}/${item.media}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={item.media ? `${BASE_SERVER_URL}/${item.media}` : ""}
                          onError={(e) => (e.target.src = 'https://via.placeholder.com/150')}
                          alt={item.description}
                          className="card-img"
                        />
                      )}
                    </div>
                    <div className="card-body">
                      <p className="card-text">{item.description}</p>
                    </div>
                    <div className="card-body">
                      <button onClick={() => handleEdit(item)} className="btn btn-primary mr-2">Edit</button>
                      <button onClick={() => handleDelete(item._id)} className="btn btn-danger">Delete</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
                 ) : (
                  <div className="text-center my-5">No data available</div>
                )}
          </div>

          {showModal && (
            <div className="modal" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Media</h5>
                    <button onClick={() => setShowModal(false)} className="close">&times;</button>
                  </div>
                  <div className="modal-body">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newDescription}
                      onChange={(e) => setNewDescription(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button onClick={handleSave} className="btn btn-success">Save</button>
                    <button onClick={() => setShowModal(false)} className="btn btn-secondary">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Gallery;
