import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import img from '../img/gallery/team1.png';
import img2 from '../img/gallery/team2.png';
import img3 from '../img/gallery/team3.png';
import img4 from '../img/gallery/team4.png';
import { Link } from 'react-router-dom';
// import '../style/Team.css'; 

const Team = () => {
  return (
    <>
      <section className="team-area section-padding40 fix ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="section-tittle text-center mb-55">
                <h2>Community Experts</h2>
              </div>
            </div>
          </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            
            navigation
            pagination={{ clickable: true }}
            loop={true}
            modules={[Navigation, Pagination]} // Correctly specify Swiper modules
          >
            <SwiperSlide  >
              <div className="single-cat text-center">
                <div className="cat-icon">
                  <img src={img} alt="Mr. Urela" />
                </div>
                <div className="cat-cap" style={{marginBottom:"37px"}}>
                  <h5><Link to="#">Mr. Urela</Link></h5>
                  <p>The automated process all your website tasks.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-cat text-center">
                <div className="cat-icon">
                  <img src={img2} alt="Mr. Uttom" />
                </div>
                <div className="cat-cap">
                  <h5><Link to="#">Mr. Uttom</Link></h5>
                  <p>The automated process all your website tasks.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-cat text-center">
                <div className="cat-icon">
                  <img src={img3} alt="Mr. Shakil" />
                </div>
                <div className="cat-cap">
                  <h5><Link to="#">Mr. Shakil</Link></h5>
                  <p>The automated process all your website tasks.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-cat text-center">
                <div className="cat-icon">
                  <img src={img4} alt="Mr. Arafat" />
                </div>
                <div className="cat-cap">
                  <h5><Link to="#">Mr. Arafat</Link></h5>
                  <p>The automated process all your website tasks.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-cat text-center">
                <div className="cat-icon">
                  <img src={img3} alt="Mr. Saiful" />
                </div>
                <div className="cat-cap">
                  <h5><Link to="#">Mr. Saiful</Link></h5>
                  <p>The automated process all your website tasks.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Team;
