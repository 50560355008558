import React, { useState } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Swal from 'sweetalert2';  // Import SweetAlert
import { BASE_SERVER_URL } from '../utils/constant/Api';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.number) errors.number = 'Number is required';
    if (!formData.message) errors.message = 'Message is required';
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await fetch( `${BASE_SERVER_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success
        setFormData({
          name: '',
          email: '',
          number: '',
          message: '',
        });
        Swal.fire({
          title: 'Success!',
          text: 'Message sent successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        // Handle error
        Swal.fire({
          title: 'Error!',
          text: 'Failed to send message',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: 'Error!',
        text: 'An unexpected error occurred',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: '10pc' }}>
        <div className="d-none d-sm-block mb-5 pb-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.36355087862!2d78.20013497519928!3d26.217374377068392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3976c102743e8429%3A0xc9ebf189795bc16c!2stechieshubhdeep%20it%20solutions!5e0!3m2!1sen!2sin!4v1726033135121!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="contact-title">Get in Touch</h2>
          </div>
          <div className="col-lg-8">
            <form
              className="form-contact contact_form"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-control w-100"
                      name="message"
                      id="message"
                      cols="30"
                      rows="9"
                      placeholder="Enter Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    {formErrors.message && <div className="error">{formErrors.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      className="form-control valid"
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && <div className="error">{formErrors.name}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      className="form-control valid"
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && <div className="error">{formErrors.email}</div>}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="number"
                      id="number"
                      type="text"
                      placeholder="Enter Number"
                      value={formData.number}
                      onChange={handleChange}
                    />
                    {formErrors.number && <div className="error">{formErrors.number}</div>}
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <button type="submit" className="button button-contactForm boxed-btn">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
