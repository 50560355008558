import React from 'react'
import img from '../img/logo/logo2_footer.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer>
    <div class="footer-wrappper footer-bg">
     
       <div class="footer-area footer-padding">
           <div class="container">
               <div class="row justify-content-between">
                   <div class="col-xl-4 col-lg-5 col-md-4 col-sm-6">
                       <div class="single-footer-caption mb-50">
                           <div class="single-footer-caption mb-30">
                    
                               <div class="footer-logo mb-25">
                                   <img src={img} alt=""/>
                               </div>
                               <div class="footer-tittle">
                                   <div class="footer-pera">
                                       <p>Empowering the next generation of creators with cutting-edge 3D skills through Skill India.</p>
                                   </div>
                               </div>
              
                               <div class="footer-social">
                                   <Link to="#"><FaXTwitter /></Link>
                                   <Link to="https://bit.ly/sai4ull"><FaFacebookF /></Link>
                                   <Link to="#"><FaInstagram /></Link>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="col-xl-2 col-lg-3 col-md-4 col-sm-5">
                       <div class="single-footer-caption mb-50">
                           <div class="footer-tittle">
                               <h4>Our Courses</h4>
                               <ul>
                                   <li><Link to="#">3D Modeling</Link></li>
                                   <li><Link to="#">Animation</Link></li>
                                   <li><Link to="#">Virtual Reality</Link></li>
                                   <li><Link to="#">3D Printing</Link></li>
                                   <li><Link to="#">Game Design</Link></li>
                               </ul>
                           </div>
                       </div>
                   </div>
                   <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
                       <div class="single-footer-caption mb-50">
                           <div class="footer-tittle">
                               <h4>Support</h4>
                               <ul>
                                   <li><Link to="#">FAQs</Link></li>
                                   <li><Link to="/contact">Contact Us</Link></li>
                                   <li><Link to="#">Technical Support</Link></li>
                                   <li><Link to="#">Community Forum</Link></li>
                                   <li><Link to="#">Skill Certification</Link></li>
                               </ul>
                           </div>
                       </div>
                   </div>
                   <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                       <div class="single-footer-caption mb-50">
                           <div class="footer-tittle">
                               <h4>Company</h4>
                               <ul>
                                   <li><Link to="/About">About Us</Link></li>
                                   <li><Link to="#">Our Mission</Link></li>
                                   <li><Link to="#">Careers</Link></li>
                                   <li><Link to="#">News & Media</Link></li>
                                   <li><Link to="#">Partnerships</Link></li>
                               </ul>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
 
       <div class="footer-bottom-area">
           <div class="container">
               <div class="footer-border">
                   <div class="row d-flex align-items-center">
                       <div class="col-xl-12 ">
                           <div class="footer-copy-right text-center">
                               <p>
                                 Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved by Techies Shubhdeep it solution
                          </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     
     </div>
 </footer>
  )
}

export default Footer
