import React, { useState } from 'react';
import Axios from 'axios';
import Topbar from '../../scenes/global/Topbar';
import Sidebar from '../../scenes/global/Sidebar';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { BASE_SERVER_URL } from '../../utils/constant/Api';
const Addgallery = () => {
  const [description, setDescription] = useState('');
  const [media, setMedia] = useState(null); // File upload
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [togglebar ,setTogglebar] = useState()
  const handleMediaUpload = (e) => {
    setMedia(e.target.files[0]);
  };
  const handleSidebarToggle = (isOpen) => {
    setTogglebar(isOpen);
  };
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('description', description);
    formData.append('media', media);

    try {
      const response = await Axios.post(`${BASE_SERVER_URL}/gallery`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Gallery item added successfully', response.data);
      Swal.fire({
        icon: 'success',
        title: 'Gallery item added!',
        text: 'Your gallery item has been successfully added.',
      },    navigate("/GalleryComponent"));
      // Reset form after submission
      setDescription('');
      setMedia(null);
    } catch (error) {
      console.error('Error adding gallery item:', error);
    }
  };

  return (
    <>
      <Topbar />
      <Sidebar setonTogglebar={handleSidebarToggle} />
      <div className={`container mt-4  ${togglebar ? 'sidebar-open' : ''} `}>
        <h1 className="mb-4 table-list">Add Gallery Item</h1>
        <form onSubmit={handleSubmit} className='table-list'>
          <div className="form-group mb-3 ">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              className="form-control"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="media">Upload Image</label>
            <input
              type="file"
              className="form-control"
              id="media"
              onChange={handleMediaUpload}
            />
          </div>
          <button type="submit"  className="btn btn-primary">
            Add Gallery Item
          </button>
        </form>
      </div>
    </>
  );
};

export default Addgallery;
