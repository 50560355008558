import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Topbar from '../../scenes/global/Topbar';
import Sidebar from '../../scenes/global/Sidebar';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BASE_SERVER_URL } from '../../utils/constant/Api';

const ProductList = () => {
  const [togglebar, setTogglebar] = useState();
  const [products, setProducts] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [editingProduct, setEditingProduct] = useState(null);

  const handleSidebarToggle = (isOpen) => {
    setTogglebar(isOpen);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await Axios.get(`${BASE_SERVER_URL}/productget`);
      setProducts(response.data.products);
      console.log(response.data.products

      )
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleEdit = async (productId) => {
    const product = products.find((p) => p._id === productId);
    setEditingProduct(product);

    const { value: formValues } = await Swal.fire({
      title: 'Edit Product',
      html: `
        <input id="name" class="swal2-input" placeholder="Name" value="${product.name}">
        <input id="originalPrice" class="swal2-input" placeholder="Original Price" type="number" value="${product.originalPrice}">
        <input id="course" class="swal2-input" placeholder="Course" value="${product.course}">
        <input id="year" class="swal2-input" placeholder="Year" type="number" value="${product.year}">
        <input id="price" class="swal2-input" placeholder="Price" type="number" value="${product.Price}">
        <input id="category" class="swal2-input" placeholder="Category" value="${product.category}">
        
      `,
      focusConfirm: false,
      
      preConfirm: () => {
        const name = document.querySelector('#name')?.value;
        const originalPrice = document.querySelector('#originalPrice')?.value;
        const course = document.querySelector('#course')?.value;
        const year = document.querySelector('#year')?.value;
        const price = document.querySelector('#price')?.value;
        const category = document.querySelector('#category')?.value;
        const img = document.querySelector('#img')?.files[0];

        if (!name || !originalPrice || !course || !year || !price || !category) {
          Swal.showValidationMessage('Please fill all fields');
          return false;
        }

        return { name, originalPrice, course, year, price, category, img };
      },
    });

    if (formValues) {
      const { name, originalPrice, course, year, price, category, img } = formValues;

      let imgUrl = product.img;
      if (img) {
        imgUrl = await uploadImage(img);
      }

      const updatedData = { name, originalPrice, course, year, price, category, img: imgUrl };
      updateProduct(productId, updatedData);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await Axios.post(`${BASE_SERVER_URL}/uploadImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: `${token}`,
        },
      });
      return response.data.imageUrl; // Adjust according to your API response
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const updateProduct = async (productId, updatedData) => {
    try {
      await Axios.put(`${BASE_SERVER_URL}/product/${productId}`, updatedData, {
        headers: { token: `${token}` },
      });
      fetchProducts();
      Swal.fire('Updated!', 'The product has been updated.', 'success');
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleDelete = async (productId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Axios.delete(`${BASE_SERVER_URL}/product/${productId}`, {
            headers: { token: `${token}` },
          });
          fetchProducts();
          Swal.fire('Deleted!', 'The product has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting product:', error);
        }
      }
    });
  };

  return (
    <>
      <Topbar />
      <Sidebar setonTogglebar={handleSidebarToggle} />
      <div className={`container mt-4 ${togglebar ? 'sidebar-open' : ''}`}>
        <h1 className="mb-4 table-list">Product List</h1>
        <div className="table-responsive table-list">
          <table className="table table-striped table-hover ">
            <thead className="table-dark">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Original Price</th>
                <th>Course</th>
                <th>Year</th>
                <th>Price</th>
                <th>Category</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product._id}>
                  <td>{index + 1}</td>
                  <td>{product.name}</td>
                  <td>{product.originalPrice}</td>
                  <td>{product.course}</td>
                  <td>{product.year}</td>
                  <td>{product.Price}</td>
                  <td>{product.category}</td>
                  <td>
                    {product.media ? (
                      <img
                        src={`${BASE_SERVER_URL}/${product.media}`}
                        alt={product.name}
                        style={{ width: '100px' }}
                        className="img-fluid"
                      />
                    ) : (
                      'No Image'
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm me-2"
                      onClick={() => handleEdit(product._id)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(product._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProductList;
