import React, { useState, useEffect } from 'react';
import img from '../img/logo/logo.png';
import '../style/Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { IoReorderThreeOutline, IoPersonCircleOutline } from "react-icons/io5";
import Swal from 'sweetalert2'; // Import SweetAlert2

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if token exists in localStorage
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token);
    }, []);

    const handlecourse = () => {
        navigate("/course");
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleProfileMenu = () => {
        setProfileMenuOpen(!isProfileMenuOpen);
    };

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('token');
                localStorage.removeItem('id');
                localStorage.removeItem('role');
                localStorage.removeItem('name');
                setIsLoggedIn(false);
                navigate('/'); // Redirect to home or login page after logout
                Swal.fire(
                    'Logged Out!',
                    'You have been logged out successfully.',
                    'success'
                );
            }
        });
    };

    const handleUserorder = () => {
        navigate("/Userorder");
    };

    return (
        <>
            <header>
                <div className="header-area header-transparent coloreation" style={{ position: "fixed" }}>
                    <div className="main-header">
                        <div className="header-bottom header-sticky" style={{ backgroundColor: "linear-gradient(to bottom, #c054ff 0%, #5274ff 100%)" }}>
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2">
                                        <div className="logo">
                                            <img src={img} alt="Logo" />
                                        </div>
                                    </div>
                                    <div className="col-xl-10 col-lg-10">
                                        <div className="menu-wrapper d-flex align-items-center justify-content-end">
                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li className="active"><Link to="/">Home</Link></li>
                                                        <li><Link  to='/course' style={{ cursor: "pointer" }}>Courses</Link></li>
                                                        <li><Link to="/About">About</Link></li>
                                                        <li><Link to="/contact">Contact</Link></li>
                                                        <li><Link to="/GalleryComponent">Gallery</Link></li>
                                                        {isLoggedIn ? (
                                                            <li className="profile-menu">
                                                                <button onClick={toggleProfileMenu} className="profile-icon">
                                                                    <IoPersonCircleOutline style={{ height: "38px", width: "41px" }} />
                                                                </button>
                                                                {isProfileMenuOpen && (
                                                                    <div className="profile-dropdown">
                                                                        <button onClick={handleUserorder} className="btn  logout-btn">My order</button>
                                                                        <button onClick={handleLogout} className="btn  logout-btn">Log out</button>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ) : (
                                                            <li className="button-header">
                                                                <Link to="/login" className="btn btn3 login-btn">Log in</Link>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </nav>
                                            </div>
                                            <div className="d-lg-none">
                                                <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                                                    <IoReorderThreeOutline style={{ height: "38px", width: "41px", marginTop: "-31px" }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`mobile-menu d-lg-none ${isMobileMenuOpen ? 'open' : ''}`}>
                                    <nav>
                                        <ul id="navigation" style={{textAlign:"center"}}>
                                            <li className="active"><Link to="/">Home</Link></li>
                                            <li><Link to='/course' style={{ cursor: "pointer" }}>Courses</Link></li>
                                            <li><Link to="/About">About</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                            <li><Link to="/GalleryComponent">Gallery</Link></li>
                                            {isLoggedIn ? (
                                                <li className="profile-menu">
                                                    <button onClick={toggleProfileMenu} className="profile-icon">
                                                        <IoPersonCircleOutline  style={{ height: "38px", width: "41px" }} />
                                                    </button>
                                                    {isProfileMenuOpen && (
                                                        <div className="profile-dropdown">
                                                            <button onClick={handleUserorder} className="btn  logout-btn">My order</button>
                                                            <button onClick={handleLogout} className="btn btn3 logout-btn">Log out</button>
                                                        </div>
                                                    )}
                                                </li>
                                            ) : (
                                                <li className="button-header">
                                                    <Link to="/login" className="btn btn3 login-btn">Log in</Link>
                                                </li>
                                            )}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
