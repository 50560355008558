import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import video from '../img/login-bg.mp4';
import img from '../img/logo/loder.png';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_SERVER_URL } from '../utils/constant/Api';

const Login = () => {
  // State to handle form input
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_SERVER_URL}/login`, {
        email,
        password,
      });
      console.log(response.data)
      
      // Store token and role in local storage
      localStorage.setItem('token', response.data.token);
      const role = response.data.userData.role  
      localStorage.setItem('role', role);
      localStorage.setItem('name', response.data.userData.name);
      localStorage.setItem('id', response.data.userData._id);

      // Handle success
      Swal.fire({
        title: 'Success!',
        text: 'You have logged in successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        // Redirect based on user role
        if (response.data.userData.role  === 'admin') {
          navigate('/Dashboard');
        } else {
          navigate('/');
        }
      });
    } catch (err) {
      // Handle error
      Swal.fire({
        title: 'Error!',
        text: 'Login failed. Please check your credentials.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error(err);
    }
  };

  return (
    <>
      <main className="login-body">
        <video autoPlay muted loop className="background-video">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <form className="form-default" onSubmit={handleSubmit}>
          <div className="login-form">
            <div className="logo-login">
            <img src={img} alt="Logo" />
            </div>
            <h2>Login Here</h2>
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-input">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-input pt-30">
              <input type="submit" value="Login" />
            </div>
            <Link to="/" className="forget text-center"><h5>Go to home</h5></Link>
            <Link to="/Signup" className="registration text-center"><h5>Create an account? Signup</h5></Link>
          </div>
        </form>
      </main>
    </>
  );
};

export default Login;
