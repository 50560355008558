import React from 'react'

import Header from '../Component/Header';
import Footer from '../Component/Footer';
import "../style/about.css"
import img from '../img/abouy.avif'
import { Link } from 'react-router-dom';
const About = () => {
    
  return (
    
    <div style={{marginTop:"100px"}} >
            <Header />
    <link href="https://cdn.jsdelivr.net/npm/remixicon@4.2.0/fonts/remixicon.css" rel="stylesheet" />
    
    <section class="about section" id="about">
      <div class="about__container container grid">
        <h2 class="section__title-1">
          <span>About Me.</span>
        </h2>
    
        <div class="about__perfil">
          <div class="about__image">
            <img src={img} alt="image" class="about__img"/>
    
            <div class="about__shadow"></div>
    
            <div class="geometric-box"></div>
    
            <div class="about__box"></div>
          </div>
        </div>
    
        <div class="about__info">
          <p class="about__description">
          At [Your Company Name], we take the following as our understanding of what 3D printing also referred to as additive manufacturing is doing to transform how we can get our ideas to real life. This new generation technology enables you to build three-dimensional objects from scratch, in a carefully designed fashion, adding more layers as the process progresses, it is one of the most versatile and creative tools out there. No matter whether you are a casual user, an artist or a practitioner, 3D printing offers the potential of almost unlimited opportunities in a wide range of application areas, from prototyping as well as creating complex geometric shapes or components.
          </p>
    
     
    
          <div class="about__buttons">
            <Link to="/contact" class="button">
              <i class="ri-send-plane-line"></i> Contact Me
            </Link>
    
            <Link to="https://www.linkedin.com/" target="_blank" class="button__ghost">
              <i class="ri-linkedin-box-line"></i>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </div>
    
  )
}

export default About