import React from 'react'
import img from '../img/gallery/about2.png'
import { Link } from 'react-router-dom'
const Aboutarea3 = () => {
  return (
  <>
   <section class="about-area2 fix pb-padding">
    <div class="support-wrapper align-items-center">
        <div class="right-content2">
            <div class="right-img">
                <img src={img} alt="3D Learning"/>
            </div>
        </div>
        <div class="left-content2">
            <div class="section-tittle section-tittle2 mb-20">
                <div class="front-text">
                    <h2 class="">Advance Your Skills and Achieve Your Career Goals</h2>
                    <p>Elevate your expertise in 3D design and animation with our specialized courses. Gain practical knowledge and industry-recognized certifications that will propel your career forward.</p>
                    <Link to="/login" class="btn" style={{backgroundColor:"#ff9f67"}}>Get Started for Free</Link>
                </div>
            </div>
        </div>
    </div>
</section>

  </>
  )
}

export default Aboutarea3