import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import './Topbar.css'; // Import the CSS file

const Topbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Show confirmation dialog before logging out
    confirmAlert({
      title: "Confirm Logout",
      message: "Are you sure you want to log out?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              localStorage.removeItem('token');
              localStorage.removeItem('id');
              localStorage.removeItem('role');
              localStorage.removeItem('name')
              console.log("Logout successful");
            } catch (error) {
              console.error("Error while logging out:", error);
            }

            if (navigate) {
              navigate("/login");
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Box 
      display="flex" 
      justifyContent="space-between" 
      p={2}
      className="topbar" // Apply the CSS class
    >
      {/* SEARCH BAR */}
      <Box display="flex" borderRadius="3px">
        {/* Search bar content goes here */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        {/* Person icon with dropdown menu */}
        <IconButton onClick={handleMenuClick} style={{color:"white"}}>
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
