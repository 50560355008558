import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // for navigation
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert
import '../style/Coursesarea.css'
import { BASE_SERVER_URL } from '../utils/constant/Api';
const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    addressLine1: '',
    pincode: '',
    country: '',
    state: '',
    district: '',
    localArea: '',
    phoneNo: '',
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${BASE_SERVER_URL}/productget`);
        const data = await response.json();
        setCourses(data.products);
        console.log(data.products)
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleMoreCourses = () => {
    navigate('/course'); // navigate to the courses page
  };

  const handleBuyClick = (course) => {
    const token = localStorage.getItem('token');

    if (!token) {
      Swal.fire({
        icon: 'info',
        title: 'Please login',
        text: 'You need to login before purchasing a course.',
        confirmButtonText: 'OK'
      }).then(() => {
        navigate('/login'); // Navigate to login page when the user clicks OK
      });
    } else {
      setSelectedCourse(course);
      setShowPopup(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("name");
    const Id = localStorage.getItem("id");
    const orderData = {
      user: name,
      userid: Id,
      products: [
        {
          product: selectedCourse._id,
          quantity: 1,
          price: selectedCourse.Price,
        },
      ],
      totalAmount: selectedCourse.Price,
      shippingAddress: formData,
      phoneNo: formData.phoneNo,
    };

    try {
      const response = await axios.post(`${BASE_SERVER_URL}/create-order`, orderData, {
        headers: {
          token: `${token}`,
        },
      });

      if (response.data) {
        console.log('Order successfully created:', response.data);
        setShowPopup(false); 
        navigate('/Userorder');
      }
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className="courses-container mt-4">
        <h1 className='courses-title text-center p-4'>Courses</h1>
        {courses.length > 0 ? (
        <div className="courses-list">
          {courses.slice(0, 4).map(course => (
            <div key={course._id} className="course-card ">
              <img src={course.media ? `${BASE_SERVER_URL}/${course.media}` : ""} alt={course.name} className="course-image" />
              <h2 className="course-name">Name: {course.name}</h2>
              <p className="course-price">Price: ₹{course.Price}</p>
              <p className="course-price">Category: {course.category}</p>
              <button className="buy-course-btn mb-5" onClick={() => handleBuyClick(course)}>
                Buy the course
              </button>
            </div>
          ))}
        </div>
          ) : (
            <div className="text-center my-5">No data available</div>
          )}

    
        <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <div class="section-tittle text-center mt-20">
                            <Link to="/course" class="border-btn">View More Courses</Link>
                        </div>
                    </div>
                </div>
      </div>

      {showPopup && (
        <div className="popup-overlay " style={{marginTop:"3.5pc"}}>
          <div className="popup-content">
            <h2 className="popup-title">Buy {selectedCourse?.name}</h2>
            <form onSubmit={handleSubmit} className="order-form">
              <label>
                Address Line 1:
                <input
                  type="text"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Pincode:
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Country:
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                State:
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                District:
                <input
                  type="text"
                  name="district"
                  value={formData.district}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Local Area:
                <input
                  type="text"
                  name="localArea"
                  value={formData.localArea}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="text"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <button type="submit" className="submit-order-btn">
                Submit Order
              </button>
              <button type="button" className="cancel-order-btn" onClick={() => setShowPopup(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Courses;
