import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Contact from "./pages/Contact";
import Dashboard from "./scenes/dashboard";
import AddProduct from "./Component/admin/AddProduct";
import OrderList from "./Component/admin/OrderList";
import ProductList from "./Component/admin/ProductList";
import AddSlider from "./Component/admin/AddSlider";
import AdminContact from "./Component/admin/AdminContact";
import SliderList from "./Component/admin/SliderList";
import GalleryComponent from "./pages/gallery"
import Index from "./scenes/dashboard";
import Contactget from "./Component/admin/Contactget";
import Addgallery from "./Component/admin/Addgallery";
import Userorder from "./pages/Userorder";
import About from "./pages/About";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/course" element={<Courses/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/Signup" element={<Signup/>}/>
          <Route path="/contact" element={<Contact/>}/>
          
          <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/addProduct" element={<AddProduct />} />
              <Route path="/orderlist" element={<OrderList />} />
              <Route path="/productlist" element={<ProductList />} />
              <Route path="/addslider" element={<AddSlider />} />
              <Route path="/admincontact" element={<AdminContact />} />
              <Route path="/sliderlist" element={<SliderList />} />
              <Route path="/GalleryComponent" element={<GalleryComponent />} />
              <Route path="/Dashboard" element={<Index/>}/>
              <Route path="/AddProduct" element={<AddProduct/>}/>
              <Route path="/Productlist" element={<ProductList/>}/>
              <Route path="/Contactget" element={<Contactget/>}/>
              <Route path="/Addgallery" element={<Addgallery/>}/>
              <Route path="/userorder" element={<Userorder/>}/>
              <Route path="/About" element={<About/>}/>
        </Routes> 
      </BrowserRouter>
    </>
  );
};

export default App;
