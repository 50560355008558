import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // for navigation
import Coursesarea from "../Component/Coursesarea";
import Subjectarea from "../Component/Subjectarea";
import Servicearea from "../Component/Servicearea";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import axios from "axios"; // For making HTTP requests
import "../App.css";

import Swal from "sweetalert2"; // Import SweetAlert
import { BASE_SERVER_URL } from "../utils/constant/Api";
const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State for showing popup
  const [selectedCourse, setSelectedCourse] = useState(null); // Selected course for buying
  const [formData, setFormData] = useState({
    addressLine1: "",
    pincode: "",
    country: "",
    state: "",
    district: "",
    localArea: "",
    phoneNo: "",
  });

  const navigate = useNavigate();

  // Fetch courses when component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${BASE_SERVER_URL}/productget`);
        const data = await response.json();
        setCourses(data.products);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  // Handle opening the buy popup
  const handleBuyClick = (course) => {
    const token = localStorage.getItem("token");

    if (!token) {
      Swal.fire({
        icon: "info",
        title: "Please login",
        text: "You need to login before purchasing a course.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/login"); // Navigate to login page when the user clicks OK
      });
    } else {
      setSelectedCourse(course);
      setShowPopup(true);
    }
  };
  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle order submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    const Id = localStorage.getItem("id");
    const orderData = {
      user: name,
      userid: Id,
      products: [
        {
          product: selectedCourse._id,
          quantity: 1,
          price: selectedCourse.Price,
        },
      ],
      totalAmount: selectedCourse.Price,
      shippingAddress: formData,
      phoneNo: formData.phoneNo,
    };

    try {
      const response = await axios.post(
        `${BASE_SERVER_URL}/create-order`,
        orderData,
        {
          headers: {
            token: `${token}`,
          },
        }
      );

      if (response.data) {
        console.log("Order successfully created:", response.data);
        setShowPopup(false);
        navigate("/Userorder");
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="courses-container" style={{ marginTop: "7pc" }}>
        <h1 className="courses-title text-center p-4">Courses</h1>
        {courses.length > 0 ? (
          <div className="courses-list">
            {courses.map((course) => (
              <div key={course._id} className="course-card">
                <img
                  src={course.media ? `${BASE_SERVER_URL}/${course.media}` : ""}
                  alt={course.name}
                  className="course-image"
                />
                <h2 className="course-name">{course.name}</h2>
                <p className="course-price">Price: ₹{course.Price}</p>
                <button
                  className="buy-course-btn mb-5"
                  onClick={() => handleBuyClick(course)}
                >
                  Buy the course
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center my-5">No data available</div>
        )}
      </div>
      <Subjectarea />
      <Servicearea />
      <Footer />

      {/* Popup for buying course */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2 className="popup-title">Buy {selectedCourse?.name}</h2>
            <form onSubmit={handleSubmit} className="order-form">
              <label>
                Address Line 1:
                <input
                  type="text"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Pincode:
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Country:
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                State:
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                District:
                <input
                  type="text"
                  name="district"
                  value={formData.district}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Local Area:
                <input
                  type="text"
                  name="localArea"
                  value={formData.localArea}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="text"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </label>
              <button type="submit" className="submit-order-btn">
                Submit Order
              </button>
              <button
                type="button"
                className="cancel-order-btn"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Courses;
