import React from 'react'
import img from '../img/h1_hero.png'
import '../style/Banner.css'
import { Link } from 'react-router-dom'
const Banner = () => {
  return (
    
    <>
 <section class="slider-area">
    <div class="slider-active">
        <div class="single-slider slider-height d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-7 col-md-12">
                        <div class="hero__caption">
                            <h1 data-animation="fadeInLeft" data-delay="0.2s">Empower Your Future<br/> with 3D Skills</h1>
                            <p data-animation="fadeInLeft" data-delay="0.4s">Enhance your career with cutting-edge 3D skills through specialized courses and certifications from leading industry experts.</p>
                            <Link to="/login" class="btn hero-btn" data-animation="fadeInLeft" data-delay="0.7s" style={{backgroundColor:"#ff9f67"}}>Get Started Today</Link>
                        </div>
                    </div>
                </div>
            </div>          
        </div>
    </div>
</section>

    </>
  )
}

export default Banner